@tailwind base;
@tailwind components;
@tailwind utilities;

@counter-style plant {
  system: cyclic;
  symbols: "\1F33F" "\1F341" "\1F335" "\1F344";
  suffix: " ";
}

@counter-style plane {
  system: cyclic;
  symbols: 🚀 🛰️ 🛸 🛩️ ✈️ 🚁;
  suffix: " ";
}

body {
  height: 100%;
  background-color: #e9ebee;
  font-family: 'Space Mono', monospace;
}

.loader {
  position: relative;
  width: 120px;
  height: 120px;
}

.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.loader span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background: rgba(255, 45, 123, .7);
  border-radius: 50%;
  animation: animate 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
  0% {
    transform: scale(0);
    opacity: .7;
  }

  10% {
    transform: scale(1.2);
    opacity: .5;
  }

  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.rocket-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 2s linear -1.35s infinite rotating;
}

@keyframes rotating {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(370deg);
  }
}

.rocket {
  position: absolute;
  top: 80px;
  left: 85px;
  font-size: 60px;
  transform: rotate(180deg);
}

.button {
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  color: #fff;
  border: 2px solid transparent;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  padding: .5rem .5rem;
  text-align: center;
  white-space: nowrap;
}

.tech-stack-tag {
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  color: #fff;
  border: 2px solid transparent;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  padding: .2rem .5rem;
  text-align: center;
  white-space: nowrap;
}

.overlap-line {
  position: relative
}

.overlap-line::after {
  content: '';
  position: absolute;
  bottom: 20%;
  right: 0;
  width: 80%;
  height: 30%;
  background-color: rgb(147 197 253 / .3);
}

.external-link {
  position: relative;
  overflow: hidden;
}

.external-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 15%;
  right: -100%;
  border-bottom: 5px dotted #86efac;
  opacity: 0;
  transition: all .5s ease-out;
  transition-delay: 500ms;
}

.external-link:hover::before {
  opacity: 1;
  right: 0;
}

.external-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 10%;
  left: -100%;
  border-bottom: 5px dotted #22d3ee;
  opacity: 0;
  transition: all .5s ease-out;
  transition-delay: 500ms;
}

.external-link:hover::after {
  opacity: 1;
  left: 0;
}

.content-block {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-style: dashed;
  border-color: #cbbeb5;
  border-bottom-width: .2rem;
}

.content-title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #737373;
  /* text-transform: capitalize; */
}

.content-text {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #a1a1aa;
  /* text-transform: capitalize; */
}

.title-block {
  border-style: dashed;
  border-color: #cbbeb5;
  border-bottom-width: .2rem;
  padding-bottom: .5rem;
}

.title {
  font-size: 1.75rem;
  line-height: 2rem;
  border-style: solid;
  border-left-width: 1rem;
  border-color: #c7d2fe;
  padding-left: .2rem;
  /* text-transform: capitalize; */
}

.switch-holder {
  display: flex;
  max-width: 300px;
  padding: 10px 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: -8px -8px 15px rgba(255, 255, 255, .7),
    10px 10px 10px rgba(0, 0, 0, .3),
    inset 8px 8px 15px rgba(255, 255, 255, .7),
    inset 10px 10px 10px rgba(0, 0, 0, .3);
  justify-content: space-between;
  align-items: center;
}

.switch-label {
  width: 150px;
}

.switch-label i {
  margin-right: 5px;
}

.switch-toggle {
  height: 40px;
}

.switch-toggle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}

.switch-toggle input[type="checkbox"]+label {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset -8px -8px 15px rgba(255, 255, 255, .6),
    inset 10px 10px 10px rgba(0, 0, 0, .25);

}

.switch-toggle input[type="checkbox"]+label::before {
  position: absolute;
  content: '';
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  top: 8px;
  left: 8px;
  width: 45px;
  height: 25px;
  border-radius: 20px;
  background-color: rgba(56, 189, 248, .75);
  box-shadow: -3px -3px 5px rgba(255, 255, 255, .5),
    3px 3px 5px #0891b2;
  transition: .3s ease-in-out;
}

.switch-toggle input[type="checkbox"]:checked+label::before {
  left: 50%;
  content: '';
  color: #fff;
  background-color: rgba(74, 222, 128, .75);
  box-shadow: -3px -3px 5px rgba(255, 255, 255, .5),
    3px 3px 5px #15803d;
}

.skill-content {
  margin-bottom: 10px;
}

.skill-content>span {
  display: block;
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #888888;
  line-height: 17px;
  margin-bottom: 4px;
}

.progress {
  height: 8px;
  border-radius: 20px;
  background: #e4e4e7;
}

.progress-bar {
  text-align: left;
  height: 100%;
  border-radius: 20px;
  transition-duration: 3s;
}

.list-item {
  display: list-item;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

#modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 1;
}

#modal-container.one {
  transform: scaleY(0.01) scaleX(0);
  animation: unfoldIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.one .modal-background .modal {
  transform: scale(0);
  animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.one.out {
  transform: scale(1);
  animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.one.out .modal-background .modal {
  animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container .modal-background {
  display: table-cell;
  background: rgba(0, 0, 0, .8);
  text-align: center;
  vertical-align: middle;
}

#modal-container .modal-background .modal {
  background: #3e3e3e;
  opacity: .95;
  color:rgba(255, 255, 255, .8);
  padding: 50px;
  display: inline-block;
  border-radius: 3px;
  font-weight: 300;
  position: relative;
}

#modal-container .modal-background .modal h2 {
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: .75rem;
}

#modal-container .modal-background .modal p {
  font-size: 1rem;
  line-height: 1.5rem;
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(.005) scaleX(0);
  }

  50% {
    transform: scaleY(.005) scaleX(1);
  }

  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }

  50% {
    transform: scaleY(.005) scaleX(1);
  }

  100% {
    transform: scaleY(.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.draw-outline {
  display: inline-block;
  padding: 16px 28px;
  border: 2px rgba(255, 255, 255, .8) solid;
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
  color: rgba(255, 255, 255, .8);
  position: relative;
  transition: border-color 0.35s ease-in-out;
  z-index: 1;
}

.draw-outline:before,
.draw-outline:after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  box-sizing: content-box;
  z-index: -1;
  transition: transform 0.25s ease-in-out;
  padding-left: 2px;
}

.draw-outline:before {
  top: -2px;
  left: -2px;
  border-top: 2px transparent solid;
  border-right: 2px transparent solid;
}

.draw-outline:after {
  bottom: -2px;
  right: -2px;
  border-bottom: 2px transparent solid;
  border-left: 2px transparent solid;
}

.draw-outline:hover {
  color: #37b2b2;
  transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
  border-color: black;
  animation: outline-reset 0.35s 1 forwards;
}

.draw-outline:hover:before {
  animation: top-right-border 0.75s 1 0.35s forwards;
}

.draw-outline:hover:after {
  animation: bottom-left-border 0.75s 1 1.1s forwards;
}

.draw-outline--tandem:hover:after {
  animation: bottom-left-border 0.75s 1 0.35s forwards;
}

.draw-outline:active:before,
.draw-outline:active:after {
  transform: scale(1.05);
}

@keyframes outline-reset {
  0% {
    border-color: black;
  }

  100% {
    border-color: transparent;
  }
}

@keyframes top-right-border {
  0% {
    border-color: #37b2b2;
    width: 0px;
    height: 0;
  }

  50% {
    width: 100%;
    height: 0;
  }

  100% {
    border-color: #37b2b2;
    width: 100%;
    height: 100%;
  }
}

@keyframes bottom-left-border {
  0% {
    border-color: #37b2b2;
    width: 0px;
    height: 0;
  }

  50% {
    width: 100%;
    height: 0;
  }

  100% {
    border-color: #37b2b2;
    width: 100%;
    height: 100%;
  }
}

.cross {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
}

.cross:hover {
  color: #cbd5e1;
}